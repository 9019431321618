<template>
  <div class="membership-dashboard">
    <b-row>
      <b-col
        md="3"
      >
        <statistic-card-horizontal
          icon="HomeIcon"
          :statistic="summary.totalOrganizations"
          statistic-title="Farmer Groups"
          @click="goTo('organizations')"
        />
      </b-col>
      <b-col
        md="3"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="success"
          :statistic="summary.totalActiveMembers"
          statistic-title="Active Members"
          @click="goTo('active')"
        />
      </b-col>
      <b-col
        md="3"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="danger"
          :statistic="summary.totalInactiveMembers"
          statistic-title="Inactive Members"
          @click="goTo('inactive')"
        />
      </b-col>
      <b-col
        md="3"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="warning"
          :statistic="summary.totalDueRetirement"
          statistic-title="Due Retirement"
          @click="goTo('due')"
        />
      </b-col>
    </b-row>
    <div class="row">
      <div
        class="col-md-6"
      >
        <am-overlay :show-overlay="genderLoading">
          <b-card
            no-body
            style="height: 450px !important;"
          >
            <b-card-header>
              <b-card-sub-title>Genders</b-card-sub-title>
              <am-select
                v-model="selectedStatus"
                placeholder="Member Status"
                :options="memberStatuses"
                @input="getGenders"
              />
            </b-card-header>

            <b-card-body>
              <vue-apex-charts
                v-if="genderChartHasData"
                :key="memberType"
                type="pie"
                height="240"
                :options="memberGendersChart.chartOptions"
                :series="memberGendersChart.series"
              />
              <div
                v-else
                class="text-gray text-center full-width"
              >
                <strong class="">No Data Available</strong>
              </div>
            </b-card-body>
            <b-card-footer>
              <b-list-group>
                <b-list-group-item
                  v-for="memberType in memberGendersChart.analyticsData"
                  :key="memberType.gender"
                  class="d-flex justify-content-between align-items-center"
                >
                  <span class="d-flex align-items-center">
                    <span
                      style="width: 10px;height: 10px;border-radius: 5px;margin-right: 5px;"
                      :class="`bg-${memberType.color}`"
                    />
                    <span class="font-semibold">{{
                      memberType.gender
                    }}</span>
                  </span>
                  <span>{{ memberType.counts }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-card-footer>
          </b-card>
        </am-overlay>
      </div>
      <div class="col-md-6">
        <b-card
          no-body
          style="height: 450px !important;"
        >
          <b-card-header>
            <b-card-sub-title>Age Range Distribution</b-card-sub-title>
          </b-card-header>
          <b-card-body>
            <e-charts
              v-if="ageRangeHasData"
              :key="ageRangeDistKey"
              ref="line"
              autoresize
              :options="ageDistributionLineChart"
              theme="ovilia-green"
            />
            <div
              v-else
              class="text-gray text-center full-width"
            >
              <strong class="">No Data Available</strong>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <b-card
          style="height: 450px !important;"
        >
          <b-card-header>
            <b-card-sub-title>New Members (20 days and less)</b-card-sub-title>
          </b-card-header>
          <b-card-body>
            <b-table
              striped
              hover
              responsive
              :items="newMembers"
              :fields="columns"
              :style="`max-height: 350px !important;`"
            />
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-6">
        <am-overlay :show-overlay="organizationLoading">
          <b-card
            no-body
            style="height: 450px !important;"
          >
            <b-card-header>
              <b-card-sub-title>Members in Farmer Group</b-card-sub-title>
              <am-select
                v-model="selectedOrganization"
                placeholder="Farmer Groups"
                label-name="name"
                value-name="id"
                :options="organizations"
                @input="loadOrganizationMembers"
              />
            </b-card-header>
            <b-card-body>
              <vue-apex-charts
                v-if="memberOrganizationChartHasData"
                :key="organizationName"
                type="donut"
                height="240"
                class="mb-1"
                :options="organizationMembersChart.chartOptions"
                :series="organizationMembersChart.series"
              />
              <div
                v-else
                class="text-gray text-center full-width"
              >
                <strong class="">No Data Available</strong>
              </div>
            </b-card-body>
            <b-card-footer>
              <b-list-group>
                <b-list-group-item
                  v-for="organization in organizationMembersChart.analyticsData"
                  :key="organization.gender"
                  class="d-flex justify-content-between align-items-center"
                >
                  <span class="d-flex align-items-center">
                    <span
                      style="width: 10px;height: 10px;border-radius: 5px;margin-right: 5px;"
                      :class="`bg-${organization.color}`"
                    />
                    <span class="font-semibold">{{
                      organization.gender
                    }}</span>
                  </span>
                  <span>{{ organization.counts }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-card-footer>
          </b-card>
        </am-overlay>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-card sub-title="Member Registrations Comparison">
          <vue-apex-charts
            :key="memberRegistrationKey"
            type="bar"
            height="350"
            :options="memberRegistration.chartOptions"
            :series="memberRegistration.series"
          />
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BListGroup, BListGroupItem, BCardBody, BCardHeader, BCardSubTitle, BCardFooter, BTable,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line import/extensions
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import AmOverlay from '@/farmbusiness/components/AmOverlay.vue'
import theme from '../theme.json'
import membershipData from './membershipData'

ECharts.registerTheme('ovilia-green', theme)
export default {
  components: {
    AmOverlay,
    AmSelect,
    BRow,
    BCol,
    StatisticCardHorizontal,
    VueApexCharts,
    ECharts,
    BCard,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BCardHeader,
    BCardSubTitle,
    BCardFooter,
    BTable,
  },
  mixins: [membershipData],
  data() {
    return {
      memberStatuses: ['Active', 'Inactive', 'Due Retirement'],
      genderLoading: false,
      organizationLoading: false,
      selectedStatus: null,
      selectedOrganization: null,
      columns: [
        {
          key: 'member_id',
          label: 'Member #',
        },
        {
          key: 'name',
          label: 'Name',
          formatter: (value, key, item) => `${item.first_name} ${item.last_name}`,
        },
        {
          key: 'gender',
          label: 'Gender',
          formatter: value => value.toUpperCase(),
        },
        {
          key: 'date_created',
          label: 'Date Registered',
        },
      ],
    }
  },
  computed: {
    organizations() {
      return this.$store.getters['organization/organizations']
    },
    dashboardSettings() {
      return this.$store.getters['memberDashboard/dashboardSettings']
    },
    newMembers() {
      return this.$store.getters['memberDashboard/newMembers']
    },
    memberRegistrations() {
      return this.$store.getters['memberDashboard/memberRegistrations']
    },
    ageDistribution() {
      return this.$store.getters['memberDashboard/ageDistribution']
    },
    summary() {
      return this.$store.getters['memberDashboard/summary']
    },
    organizationMembers() {
      return this.$store.getters['memberDashboard/organizationMembers']
    },
    genders() {
      return this.$store.getters['memberDashboard/genders']
    },
    genderChartHasData() {
      return this.memberGendersChart.series[0] > 0 || this.memberGendersChart.series[1] > 0
    },
    ageRangeHasData() {
      return this.ageDistributionLineChart.series.data.length > 0
    },
    memberOrganizationChartHasData() {
      return this.organizationMembersChart.series[0] > 0 || this.organizationMembersChart.series[1] > 0
    },
  },
  watch: {
    memberRegistrations(val) {
      this.memberRegistration.series[0].data = val.this_year_data
      this.memberRegistration.series[0].name = `This Year (${val.this_year})`
      this.memberRegistration.series[1].data = val.last_year_data
      this.memberRegistration.series[1].name = `Last Year (${val.last_year})`
      this.memberRegistrationKey = 100
    },
    genders(val) {
      this.memberGendersChart.series[0] = val.males
      this.memberGendersChart.series[1] = val.females
      this.memberGendersChart.analyticsData[0].counts = val.males
      this.memberGendersChart.analyticsData[1].counts = val.females
      this.memberGendersChart = { ...this.memberGendersChart }
    },
    organizationMembers(val) {
      this.organizationMembersChart.series[0] = val.males
      this.organizationMembersChart.series[1] = val.females
      this.organizationMembersChart.analyticsData[0].counts = val.males
      this.organizationMembersChart.analyticsData[1].counts = val.females
      this.organizationMembersChart = { ...this.organizationMembersChart }
    },
    ageDistribution(val) {
      this.ageDistributionLineChart.xAxis.data = val.x
      this.ageDistributionLineChart.series.data = val.y
      this.ageDistributionLineChart = { ...this.ageDistributionLineChart }
    },
  },
  mounted() {
    this.selectedOrganization = this.dashboardSettings.selectedOrganization
    this.selectedStatus = this.dashboardSettings.selectedStatus
    this.$store.dispatch('memberDashboard/getSummary')
    this.$store.dispatch('memberDashboard/getAgeDistribution')
    this.$store.dispatch('memberDashboard/getNewMembers')
    this.$store.dispatch('memberDashboard/getMemberRegistrations')
  },
  methods: {
    goTo(to) {
      if (to === 'organizations') {
        this.$router.push({ name: 'organizations' })
      }
      if (to === 'active') {
        this.$store.commit('member/setSettings', { memberStatus: 'Active' })
        this.$router.push({ name: 'members' })
      } if (to === 'inactive') {
        this.$store.commit('member/setSettings', { memberStatus: 'Inactive' })
        this.$router.push({ name: 'members' })
      }
      if (to === 'due') {
        this.$store.commit('member/setSettings', { memberStatus: 'Due Retirement' })
        this.$router.push({ name: 'members' })
      }
    },
    loadOrganizationMembers(id) {
      this.$store.commit('memberDashboard/setSettings', { selectedOrganization: this.selectedOrganization })
      this.organizationLoading = true
      this.$store.dispatch('memberDashboard/getOrganizationMembers', id).finally(() => {
        this.organizationLoading = false
      })
    },
    getGenders(status) {
      this.$store.commit('memberDashboard/setSettings', { selectedStatus: this.selectedStatus })
      this.genderLoading = true
      this.$store.dispatch('memberDashboard/getGenders', status).finally(() => {
        this.genderLoading = false
      })
    },
  },
}
</script>
