export default {
  data() {
    return {
      organizationName: '',
      memberType: '',
      memberGenders: [],
      totalOrganizations: 0,
      totalActiveMembers: 0,
      totalInactiveMembers: 0,
      totalMembersDuesRetirement: 0,
      ageDistributionData: [],
      ageRangeDistKey: 0,
      ageRangeList: [],
      ageCountList: [],
      memberRegistrationKey: 0,
      memberRegistration: {
        series: [{
          name: 'This Year',
          data: [],
        },
        {
          name: 'Last Year',
          data: [],
        },
        ],
        chartOptions: {
          colors: ['#afed42', '#ffc62b'],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '40%',
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['transparent'],
          },

          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          yaxis: {
            title: {
              text: 'Total Members',
            },
          },
          fill: {
            opacity: 1,

          },
          tooltip: {
            y: {
              formatter(val) {
                return `${val} member(s) registered`
              },
            },
          },
        },
      },
      ageDistributionLineChart: {
        // Make gradient line here
        visualMap: [
          {
            show: false,
            type: 'continuous',
            seriesIndex: 0,
            min: 0,
            max: 400,
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          data: [],
          label: 'Age range in Years',
        },
        yAxis: {
          splitLine: { show: false },
        },
        series: {
          type: 'line',
          showSymbol: false,
          data: [],
        },
      },
      memberGendersChart: {
        analyticsData: [
          { gender: 'Males', counts: 0, color: 'primary' },
          { gender: 'Females', counts: 0, color: 'warning' },
        ],
        series: [0, 0],
        chartOptions: {
          labels: ['Males', 'Females'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          chart: {
            type: 'pie',
            offsetY: 0,
            dropShadow: {
              enabled: false,
              blur: 5,
              left: 1,
              top: 1,
              opacity: 0.2,
            },
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: 5,
          },
          colors: ['#7fc72e', '#ffae00'],
          fill: {
            type: 'gradient',
            gradient: {
              gradientToColors: ['#7fc72e', '#e8a413'],
            },
          },
        },
      },
      organizationMembersChart: {
        analyticsData: [
          { gender: 'Males', counts: 0, color: 'primary' },
          { gender: 'Females', counts: 0, color: 'warning' },
        ],
        series: [0, 0],
        chartOptions: {
          labels: ['Males', 'Females'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          chart: {
            type: 'pie',
            offsetY: 0,
            dropShadow: {
              enabled: false,
              blur: 5,
              left: 1,
              top: 1,
              opacity: 0.2,
            },
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: 5,
          },
          colors: ['#7fc72e', '#ffae00'],
          fill: {
            type: 'gradient',
            gradient: {
              gradientToColors: ['#7fc72e', '#e8a413'],
            },
          },
        },
      },
    }
  },
}
